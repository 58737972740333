import React,{ useState,useEffect, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {connect} from 'react-redux';
import swal from 'sweetalert';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MUIDataTable from "mui-datatables";
import {API_URL} from '../../config.json';
import {BrowserRouter as Router,Route,Link} from 'react-router-dom'
import axios from 'axios';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import AsyncSelect from 'react-select/async';


import Stack from '@mui/material/Stack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';



import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DateFnsUtils from '@date-io/date-fns';

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from '@material-ui/pickers';

import DeleteIcon from '@material-ui/icons/Delete';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';




import {currentRouteSet} from '../../actions/actions';

import {pathSpliter,checkAuthBranchWare,dateTimeFormat,currentDateTime,dateFormat} from '../../lib/functions'
import moment from 'moment';


import _ from 'lodash';
import commaNumber from 'comma-number';
let format = commaNumber.bindWith(',', '.')

const GroupManage = ({location,currentRouteSet,currentRoute,authInfo})=>{
    const classes = useStyles();
 
  useEffect(()=>{
    getSupplierPayments();
    currentRouteSet(pathSpliter(location.pathname,1));
    getPayCode();
   getOthersAccs()
  },[])

    // Init states start
 
    
    let [pay_id,pay_id_set] = useState(0)
    let [payments,paymentsSet] = useState([])
    let [payCart,payCartSet] = useState([])
    let [accounts,accountsSet] = useState([])
    let [directIncomeAccounts,directIncomeAccountsSet] = useState([])
    let [currentLaibilityAccounts,currentLaibilityAccountsSet] = useState([])
    let [loadingList,loadingListSet] = useState(false)
    let [loadingSave,loadingSaveSet] = useState(false)

    let [loadToAccounts,loadToAccountsSet] = useState([])
    let [loadFromAccounts,loadFromAccountsSet] = useState([])
    

    let [action,action_set] = useState('create');
    let [pay_code,pay_code_set] = useState('');
    let [pay_ind_id,pay_ind_id_set] = useState(null);

    let [pay_amount,pay_amount_set] = useState('');
    let [pay_total,pay_total_set] = useState(0);
    let [discount_amount,discount_amount_set] = useState(0);
    let [discount_amount_per,discount_amount_per_set] = useState(0);
    let [tax_amount,tax_amount_set] = useState(0);
    let [tax_amount_per,tax_amount_per_set] = useState(0);

    let [from_balance,from_balance_set] = useState(0);
    let [to_balance,to_balance_set] = useState(0);

    let [narration,narration_set] = useState('');
    let [dueVouchers,dueVouchersSet] = useState([]);
    let [selectedVoucher,selectedVoucherSet] = useState(null);
    let [selectedBaseUnit,selectedBaseUnitSet] = useState(null);

    let [selectedDirectIncomeAcc,selectedDirectIncomeAccSet] = useState(null);
    let [selectedCurrentLibilityAcc,selectedCurrentLibilityAccSet] = useState(null);
    
    
    const [payDate, payDateSet] = useState(currentDateTime);
    const [selectedAccountTo, selectedAccountToSet] = useState(null);
    const [selectedAccountFrom, selectedAccountFromSet] = useState(null);

    let to_acc_ref = useRef(null)
    let from_acc_ref = useRef(null)
    let deu_voucher_ref = useRef(null)
    let pay_amount_ref = useRef(null)
    let discount_amount_ref = useRef(null)
    let tax_amount_ref = useRef(null)
    let pay_code_ref = useRef(null)
    let creation_date_ref = useRef(null)
    let narration_ref = useRef(null)

    let [is_voucher_pay,is_voucher_pay_set] =  useState('no');
    let [is_discount,is_discount_set] =  useState('no');
    let [is_tax,is_tax_set] =  useState('no');



    let getOthersAccs = async ()=>{

      await  axios.post(`${API_URL}/api/get-accounts-by-type`,{acc_type_id:'direct_income'},{headers:{'auth-token':authInfo.token}}).then(res=>{
        directIncomeAccountsSet(res.data) 
        selectedDirectIncomeAccSet(res.data.length != 0 ? res.data[0]:null)
      })

      await  axios.post(`${API_URL}/api/get-accounts-by-type`,{acc_type_id:'dutie_&_tax'},{headers:{'auth-token':authInfo.token}}).then(res=>{
        currentLaibilityAccountsSet(res.data) 
        selectedCurrentLibilityAccSet(res.data.length != 0 ? res.data[0]:null)
      })

      }
  
  useEffect(()=>{
    getSupplierPayments()
  },[payDate])

  useEffect(()=>{
    if(is_voucher_pay == 'yes' && selectedAccountTo != null){
      getDueVouchers()
    }
  },[is_voucher_pay,selectedAccountTo])


  let getDueVouchers = async ()=>{
    await axios.post(`${API_URL}/api/get-due-purchase-vouchers`,{supplierId: selectedAccountTo != null ? selectedAccountTo.acc_id : 0},{headers:{'auth-token':authInfo.token}}).then(res=>{
      dueVouchersSet(res.data)
      });
  }


  useEffect(()=>{
    if(is_discount == 'yes' && directIncomeAccounts.length == 0){
       axios.post(`${API_URL}/api/get-accounts-by-type`,{acc_type_id:'direct_income'},{headers:{'auth-token':authInfo.token}}).then(res=>{
        directIncomeAccountsSet(res.data)
      })
    }
  },[is_discount])

  useEffect(()=>{
    if(is_tax == 'yes' && currentLaibilityAccounts.length == 0){
       axios.post(`${API_URL}/api/get-accounts-by-type`,{acc_type_id:'current_liability'},{headers:{'auth-token':authInfo.token}}).then(res=>{
        currentLaibilityAccountsSet(res.data)
      })
    }
  },[is_tax])

   
    const payDateChange = (date) => {
        payDateSet(date);
      };


    
    const getSupplierPayments = async ()=>{
        await axios.post(`${API_URL}/api/get-creditor-pay-with-details`,{oneDate:payDate},{headers:{'auth-token':authInfo.token}}).then(res=>{
        paymentsSet(res.data)
        })
    }


    
    const [account_name_from, account_name_from_set] = useState("");
    const [account_name_to, account_name_to_set] = useState("");

    useEffect(()=>{
      getLoadFromAccounts()
    },[account_name_from])

    useEffect(()=>{
      getLoadToAccounts()
    },[account_name_to])



    const getLoadToAccounts = async() => {
        await axios.post(`${API_URL}/api/get-accounts-by-search`,{query:account_name_to,search:true,type:'creditor'},{headers:{'auth-token':authInfo.token}}).then(res=>{
           loadToAccountsSet(res.data)
        })
    };

    const getLoadFromAccounts = async() => {
      await axios.post(`${API_URL}/api/get-accounts-by-search`,{query:account_name_from,search:true,
       multiType:['cash_in_hand','bank_account']
      },{headers:{'auth-token':authInfo.token}}).then(res=>{
         loadFromAccountsSet(res.data)
      })
  };

    const getPayCode = async() => {
      return  await axios.post(`${API_URL}/api/get-creditor-payment-code`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
          pay_code_set(res.data)
        })
    };


    useEffect(()=>{
      if(selectedAccountTo != null){
         axios.post(`${API_URL}/api/get-sundry-creditor-balance`,{
          supplierId : selectedAccountTo.acc_id
        },{headers:{'auth-token':authInfo.token}}).then(res=>{
          to_balance_set(res.data.total_balance)
        })
      }
    },[selectedAccountTo])

    useEffect(()=>{
      if(selectedAccountFrom != null){
         axios.post(`${API_URL}/api/get-accounts-balance`,{
          accId : selectedAccountFrom.acc_id,
          accType : "'cash_in_hand'"
        },{headers:{'auth-token':authInfo.token}}).then(res=>{
          from_balance_set(res.data.total_balance)
        })
      }
    },[selectedAccountFrom])


    useEffect(()=>{
      let total = (pay_amount)  - ( parseFloat(tax_amount))
      pay_total_set(total)
    },[pay_amount,discount_amount,tax_amount])
  
  



    // Init states end
    // Methods script start
    const saveFormAction = async ()=>{

            let totalDiscountAmount = _.sumBy(payCart,(tran)=>parseFloat(tran.discount_amount))
            let totalTexAmount = _.sumBy(payCart,(tran)=>parseFloat(tran.tax_amount))
            let totalPayTotalAmount = _.sumBy(payCart,(tran)=>parseFloat(tran.pay_total))

           if(payCart.length == 0){
              swal({
                title:`Payment Cart is Empty.`,
                icon:'warning'
              });
              return false
            }else{
            let payLoad = {
              pay:{
                pay_code:pay_code.trim(),
                creation_date:payDate,
                discount_total:totalDiscountAmount,
                tax_total:totalTexAmount,
                pay_total : totalPayTotalAmount,
                narration,
                pay_id,
                action
              },
              payDetail:payCart
            }
          loadingSaveSet(true);
            await axios.post(`${API_URL}/api/save-creditor-payment`,{  
              ...payLoad
            },{headers:{'auth-token': authInfo.token}}).then(res=>{
              loadingSaveSet(false);
             if(res.data.error == false){
               swal({
                 title: res.data.msg,
                 icon:'success'
               })
               payDateSet(currentDateTime)
               payCartSet([])
               action_set('create')
               narration_set('')
               pay_id_set(0)
               getPayCode()
               getSupplierPayments()
             }else{
              swal({
                title: res.data.msg,
                icon:'warning'
              })
             }
            });

            }
         
            
          
          
    }


  const removeJournalTran = (sl)=>{
    let preCopy =  [...payCart];
    preCopy.splice(sl, 1);
    payCartSet(preCopy)

  }

  const addTOJournal = ()=>{
    

    if(selectedAccountTo == null){
      swal({
        title:`Select a  To  Account/ Creditor`,
        icon:'warning'
      });
      return false
    }else if(selectedAccountFrom == null){
        swal({
          title:`Select a From Account`,
          icon:'warning'
        });
        return false;
      }else if( ( discount_amount > 0 )&&  selectedDirectIncomeAcc == null ){
        swal({
          title:`Select a Discount / Direct Income Account`,
          icon:'warning'
        });
        return false
      }else if( ( tax_amount > 0 )&&  selectedCurrentLibilityAcc == null ){
        swal({
          title:`Select a Tax / Current Laibility Account`,
          icon:'warning'
        });
        return false;
      }else{

      let check =  payCart.findIndex((tran)=>{
        return tran.to_acc_id == selectedAccountTo.acc_id
      })

      if(check == -1 && payCart.length != 0){
        swal({
          title:`One Receipt for  should be one Creditor's Transaction`,
          icon:'warning'
        });
        return false
      }


      let tran = {
        to_acc_id : selectedAccountTo.acc_id,
        from_acc_id : selectedAccountFrom.acc_id,
        to_acc_name : selectedAccountTo.acc_name,
        from_acc_name : selectedAccountFrom.acc_name,
        voucher_no    : selectedVoucher != null ? selectedVoucher.voucher_no : '',
        direct_income_id    : selectedDirectIncomeAcc != null ? selectedDirectIncomeAcc.acc_id : 0,
        current_liability_id    : selectedCurrentLibilityAcc != null ? selectedCurrentLibilityAcc.acc_id : 0,
        pay_amount : pay_amount =='' ? 0 :pay_amount,
        discount_amount : discount_amount =='' ? 0 :discount_amount,
        tax_amount : tax_amount =='' ? 0 : tax_amount,
        pay_total : pay_total =='' ? 0 : pay_total,
      }
      if(pay_ind_id == null){
        payCartSet([...payCart,tran])
      }else{
        let preCopy =  [...payCart];
        preCopy[pay_ind_id] = tran
        payCartSet(preCopy)

      }


      pay_ind_id_set(null)
      selectedAccountToSet(null)
      selectedAccountFromSet(null)
      pay_amount_set('')
      discount_amount_set(0)
      discount_amount_per_set(0)
      tax_amount_set(0)
      tax_amount_per_set(0)

        is_voucher_pay_set('no')
      
        is_discount_set('no')
      
        is_tax_set('no')

        selectedVoucherSet(null)
        selectedDirectIncomeAccSet(null)
        selectedCurrentLibilityAccSet(null)
      
      
     }

    
     

   
    }

    // Methods script end
    const ActionOptions = (props)=>{
      return(<div style={{textAlign:'right'}}> 

{
    authInfo.role !='user'?(
    <>
        <EditIcon style={{cursor:'pointer',fontSize: '28px',color: 'rgb(15, 126, 119)'}} onClick={()=>payEdit(props.rowData)}/>
      
        <DeleteIcon style={{cursor:'pointer',color: '#ff0202',fontSize: '28px'}} onClick={()=>payDelete(props.rowData)}/>
            
   </>):''
 }
      
      </div>)
    
    }


    const payEdit = (row,index)=>{
      let payment =  payments.filter((pay)=>pay.pay_id == row[0])
      payment = payment[0]
      payCartSet(payment.details)
      
      pay_id_set(payment.pay_id)
      action_set('update')
      pay_code_set(payment.pay_code)
      payDateSet(payment.creation_date)
      narration_set(payment.narration)
     
    }


    const editJournalTran = (row,sl)=>{
      pay_ind_id_set(sl)
      selectedAccountToSet({acc_name:row.to_acc_name,acc_id:row.to_acc_id})
      selectedAccountFromSet({acc_name:row.from_acc_name,acc_id:row.from_acc_id})

      selectedDirectIncomeAccSet({acc_name:row.direct_income_acc_name,acc_id:row.direct_income_id})
      selectedCurrentLibilityAccSet({acc_name:row.current_liability_acc_name,acc_id:row.current_liability_id})

      selectedVoucherSet({voucher_no:row.voucher_no})
      pay_amount_set(row.pay_amount)
      discount_amount_set(row.discount_amount)
      tax_amount_set(row.tax_amount)

      if(row.voucher_no != ''){
        is_voucher_pay_set('yes')
      }
      if(row.discount_amount != 0){
        is_discount_set('yes')
      }
      if(row.tax_amount != 0){
        is_tax_set('yes')
      }
    }


    const payDelete = async (row)=>{
      swal({
        title:'Are you sure delete this?',
        icon:'warning',
        buttons:true
      }).then(async(yes)=>{
        if(yes){
          let Payment =  payments.filter((pay)=>pay.pay_id == row[0])
          await axios.post(`${API_URL}/api/delete-creditor-payment`,{pay_id:Payment[0].pay_id},
          {headers:{'auth-token':authInfo.token}}).then(res=>{
                  getSupplierPayments();
                  swal({
                    title: res.data.msg,
                    icon:'success'
                  })
                  
                })
        }else{
          return false
        }
      })
      

          }
   
    const columns = [
      {name: "pay_id",options: { display: 'excluded' }},
      {name:"SL",options: {filter: false,sort: false,
        customBodyRender:(value,tableMeta)=>{
        return ( <p>{parseFloat(tableMeta.rowIndex)+1}</p> ); 
      }
      },headerStyle: {
        textAlign:'left'
      }},
      {name: "pay_code",label: "Payment Code",options: {filter: true,sort: true}},
      {name: "creation_date",label: "Payment Date",options: {filter: true,sort: true,
        customBodyRender:(value,tableMeta)=>{
          return(<p>{ moment(tableMeta.rowData[3]).format(dateFormat) }</p>) 
        }
      }},
      // {name: "discount_total",label: "Total Discount",options: {filter: true,sort: true}},
      {name: "acc_name",label: "Creditor/ Supplier",options: {filter: true,sort: true}},
      {name: "pay_total",label: "Payment Total",options: {filter: true,sort: true}},
      {name: "discount_total",label: "Discount",options: {filter: true,sort: true}},
      {name: "narration",label: "Narration",options: {filter: true,sort: true}},
      {name: "user_name",label: "Created By",options: {filter: true,sort: true}},


      {name:"actions",options: {filter: false,sort: false,
        customBodyRender:(value,tableMeta)=>{
          return ( <ActionOptions   value={value} rowIndex={tableMeta.rowIndex}  rowData={tableMeta.rowData} 
             /> ); 
      }
      },headerStyle: {
        textAlign:'right'
      }}
     ];
            
     const options = {
       filterType: 'checkbox',
       selectableRows: 'none',
       display: "excluded"
      }


    return (
    <div className={classes.root}>

     <Paper className={classes.paper} style={{marginTop:'-15px'}}>
    <h2 className={classes.pageEntryLabel}>Creditor/ Supplier Payment Entry </h2>
      <Grid container spacing={2}>
      <Grid  xs={12} sm={4}>
      <Grid  item xs={12} sm={12} style={{marginBottom:'10px'}}>
      <Autocomplete
              autoHighlight={true}
              openOnFocus={true}
              style={{ width: '100%' }}
              options={loadToAccounts} 
              size="small"
              classes={{
                option: classes.option,
              }}
              
              getOptionLabel={(option) => option.acc_name}
              value={selectedAccountTo}
              onChange={(e,selectedObj)=>{
                selectedAccountToSet(selectedObj)
                if (e.key === "Enter") {
                  from_acc_ref.current.focus();
                }
              }}
              renderInput={(params) => (
                <TextField
                  inputRef={to_acc_ref}
                  onChange={(e)=>{
                    account_name_to_set(e.target.value);
                   
                  }}
                  {...params}
                  label="To Account / Creditor"
                  variant="outlined"
                
                />
              )}
          />
{
  selectedAccountTo != null?(
<p className="balance-availity">Payable  :  {parseFloat(to_balance).toFixed(2)}</p>

  ):''
}

     
      </Grid>
     


     


<Grid  item xs={12} sm={12} style={{marginBottom:'10px'}}>
<Autocomplete
              autoHighlight={true}
              openOnFocus={true}
              style={{ width: '100%' }}
              options={loadFromAccounts} 
              size="small"
              classes={{
                option: classes.option,
              }}
              
              getOptionLabel={(option) => option.acc_name}
              value={selectedAccountFrom}
              onChange={(e,selectedObj)=>{
                selectedAccountFromSet(selectedObj)
                if (e.key === "Enter") {
                  pay_amount_ref.current.focus()
                 }
              }}
              renderInput={(params) => (
                <TextField
                  inputRef={from_acc_ref}
                  onChange={(e)=>{
                    account_name_from_set(e.target.value);
                   
                  }}
                  {...params}
                  label="From Account"
                  variant="outlined"
                
                />
              )}
          />
{
  selectedAccountFrom != null ? (
<p className="balance-availity">Balance  : {parseFloat(from_balance).toFixed(2)}</p> 

  ):''
}



</Grid>




<Grid item xs={12} sm={12} style={{textAlign:'left',margin:'10px',color: '#222',
    fontWeight: 'bold',display:'none'}}>
              <input type="checkbox" 
               id="is_voucher_pay" checked={is_voucher_pay=='yes'?true:false} name="is_voucher_pay" 
                onChange={(e)=>is_voucher_pay_set(e.target.checked?'yes':'no')}
              />
              <label for="is_voucher_pay">Is Voucher Against Payment ?</label>
        </Grid>

        <Grid item xs={12} sm={12} style={{marginBottom:'10px'}}>
        {
  is_voucher_pay == 'yes'?(<>
    <Autocomplete
              autoHighlight={true}
              openOnFocus={true}
              style={{ width: '100%' }}
              options={dueVouchers} 
              size="small"
              classes={{
                option: classes.option,
              }}
              getOptionLabel={(option) => option.voucher_no}
              value={selectedVoucher}
              
              onChange={(event,selectedObj)=>{
                  selectedVoucherSet(selectedObj)
              }}
              renderInput={(params) => (
                <TextField
                inputRef={deu_voucher_ref}
                onKeyDown={event => {
                    if (event.key === "Enter") {
                     pay_amount_ref.current.focus()
                    }
                  }}

                  {...params}
                  label="Choose a Due Voucher"
                  variant="outlined"
                
                />
              )}
          />

  </>):''
}
        </Grid>



<Grid item xs={12} sm={12} style={{marginBottom:'10px'}}> 

<TextField type="number" 

        autoComplete='off' className={classes.fullWidth} 
        label="Payment Amount" name="pay_amount" value={pay_amount} 

        inputRef={pay_amount_ref}
        onKeyDown={event => {
            if (event.key === "Enter") {
                addTOJournal()

            }
        }}

        variant="outlined" size="small" onChange={(e)=>{
          
                 pay_amount_set(e.target.value)
           
            }} />


        
        </Grid>


        <Grid item xs={12} sm={12} style={{textAlign:'left',margin:'10px',color: '#222',
    fontWeight: 'bold',display:''}}>
              <input type="checkbox" 
               id="is_discount" checked={is_discount=='yes'?true:false} name="is_discount" 
                onChange={(e)=>is_discount_set(e.target.checked?'yes':'no')}
              />
              <label for="is_discount">Any Discount ?</label>
        </Grid>

       
            {
              is_discount == 'yes'?(<>
                    <Grid  item xs={12} sm={12} style={{marginBottom: '7px',width:'100%',display:''}}>
        <Grid  item xs={12} sm={6} style={{float:'left'}}>
            <TextField type="number"  style={{width:'100%'}}
                    
                    autoComplete='off' className={classes.fullWidth} 
                    label="Discount Amount" name="discount_amount" value={discount_amount} 
                    variant="outlined" size="small" onChange={(e)=>{

                      let discountPercent = ((100 * e.target.value)/pay_amount)
                      discount_amount_per_set(discountPercent)

                     discount_amount_set(e.target.value)

                    
                            
                    
                        }} />
            </Grid>

           
{/* 
            <Grid item xs={12} sm={5}  style={{float:'right'}}>
            <TextField type="number"  
           
                     autoComplete='off' className={classes.fullWidth} 
                    label="Discount %" name="discount_amount_per" value={discount_amount_per} 
                    variant="outlined" size="small" onChange={(e)=>{

                      let discountAmount = (pay_amount*e.target.value)/100;
                      discount_amount_set(discountAmount) 
                      discount_amount_per_set(e.target.value)

                    
                            
                    
                        }} />
                         
                         <div style={{marginBottom:'10px'}}></div>
                         
            </Grid> */}
        </Grid>
       
       
      


        <Grid item xs={12} sm={12} style={{marginBottom:'10px',display:'none'}}>
        <Autocomplete
              autoHighlight={true}
              openOnFocus={true}
              style={{ width: '100%' }}
              options={directIncomeAccounts} 
              size="small"
              classes={{
                option: classes.option,
              }}
              getOptionLabel={(option) => option.acc_name}
              value={selectedDirectIncomeAcc}
              
              onChange={(event,selectedObj)=>{
                selectedDirectIncomeAccSet(selectedObj)
              }}
              renderInput={(params) => (
                <TextField
              

                  {...params}
                  label="Choose a Discount Account"
                  variant="outlined"
                
                />
              )}
          />
        </Grid>
        </>):''
            }

          
        <Grid item xs={12} sm={12} style={{textAlign:'left',margin:'10px',color: '#222',
    fontWeight: 'bold',display:'none'}}>
              <input type="checkbox" 
               id="is_tax" checked={is_tax=='yes'?true:false} name="is_tax" 
                onChange={(e)=>is_tax_set(e.target.checked?'yes':'no')}
              />
              <label for="is_tax">Any Tax Deducted?</label>
        </Grid>



        {
          is_tax == 'yes'?(<>
              <Grid item xs={12} sm={12} style={{marginBottom: '7px',display:'none'}}>
        <Grid item xs={12} sm={6} style={{float:'left'}}>
            <TextField type="number"  
             inputRef={tax_amount_ref}
             onKeyDown={event => {
                 if (event.key === "Enter") {
                     pay_code_ref.current.focus()
                 }
             }}
                     autoComplete='off' className={classes.fullWidth} 
                    label="Tax Deducted " name="tax_amount" value={tax_amount} 
                    variant="outlined" size="small" onChange={(e)=>{

                      let texPercent = ((100 * e.target.value)/pay_amount)
                      tax_amount_per_set(texPercent)

                     tax_amount_set(e.target.value)
                    
                            
                    
                        }} />
            </Grid>

            <Grid item xs={12} sm={6} style={{float:'right'}}>
            <TextField type="number"  
                    autoComplete='off' className={classes.fullWidth} 
                    label="Tax Deducted %" name="tax_amount_per" value={tax_amount_per} 
                    variant="outlined" size="small" onChange={(e)=>{

                      let texPercent = ((100 * e.target.value)/pay_amount)
                      tax_amount_per_set(texPercent)

                     tax_amount_set(e.target.value)
                    
                            
                    
                        }} />
                         <div style={{marginBottom:'10px'}}></div>
            </Grid>

           
        </Grid>
       
         
       


        <Grid item xs={12} sm={12} style={{marginBottom: '7px',display:'none'}}>
        <Autocomplete
              autoHighlight={true}
              openOnFocus={true}
              style={{ width: '100%' }}
              options={currentLaibilityAccounts} 
              size="small"
              classes={{
                option: classes.option,
              }}
              getOptionLabel={(option) => option.acc_name}
              value={selectedCurrentLibilityAcc}
              
              onChange={(event,selectedObj)=>{
                selectedCurrentLibilityAccSet(selectedObj)
              }}
              renderInput={(params) => (
                <TextField
              

                  {...params}
                  label="Choose a Tax Account"
                  variant="outlined"
                
                />
              )}
          />
        </Grid>
          </>):''
        }

        


        <Grid item xs={12} sm={12} style={{display:'none'}}> 

<TextField type="number"  
          autoComplete='off' className={classes.fullWidth} 
        label="Payment Total" name="pay_total" value={pay_total} 
                disabled
        // inputRef={pay_total_f}
        // onKeyDown={event => {
        //     if (event.key === "Enter") {
        //         discount_amount_ref.current.focus()
        //     }
        // }}

        variant="outlined" size="small" onChange={(e)=>{
          
                 pay_total_set(e.target.value)
                
           
            }} />


        
        </Grid>



      

        
        <span>Press Enter Key to Payment Cart </span>


        <Grid item xs={12} sm={6} style={{marginTop: '20px'}}>
           <Button onClick={()=>addTOJournal()} style={{background: 'rgb(109 113 113)',color: 'white',fontSize: '10px'}}>Add To Payment</Button>
        </Grid>


      </Grid>

      <Grid item xs={12} sm={8} style={{paddingLeft:'8px',paddingTop: '0px'}}>

        <Grid item xs={12} sm={12}>
        <Grid item xs={12} sm={4} style={{float:'left'}}> 
        <TextField  autoComplete='off' className={classes.fullWidth} 
         inputRef={pay_code_ref}
         onKeyDown={event => {
             if (event.key === "Enter") {
                 creation_date_ref.current.focus()
             }
         }}
        label="Payment Code" name="pay_code" value={pay_code} 
        variant="outlined" size="small" onChange={(e)=>pay_code_set(e.target.value)} />
        </Grid>

        <Grid item xs={1} sm={2}>
          <div></div>
        </Grid>



        
        <Grid item xs={12} sm={4} style={{float:'right',marginTop: '-8px',marginLeft:'5px'}}> 
      
      <LocalizationProvider dateAdapter={AdapterDateFns} style={{marginTop: '8px'}}>
        <Stack spacing={3}>
          <DesktopDatePicker
            label="Payment Date" 
            inputFormat={dateTimeFormat}
            inputRef={creation_date_ref}
           
            value={payDate}
            onChange={(e)=>payDateChange(e)}
            renderInput={(params) => <TextField 
              onKeyDown={event => {
                if (event.key === "Enter") {
                    narration_ref.current.focus()
                }
            }}
            
              {...params} />}
          />
          
        </Stack>
        </LocalizationProvider>


  </Grid>


     
        </Grid>
     
     




        <Grid item xs={12} sm={12}>
        <TableContainer >
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
              <TableRow>
              <TableCell align="left"   style={{width:'1%'}}>SL</TableCell>
              <TableCell align="left"   style={{width:'20%'}}>To Account</TableCell>
              <TableCell align="left"   style={{width:'20%'}}>From Account</TableCell>
              {/* <TableCell align="left"  style={{width:'10%'}}>Against Voucher</TableCell> */}

              <TableCell align="right"  style={{width:'10%'}}>Payment</TableCell>
              <TableCell align="right"  style={{width:'10%',display:''}}>Discount</TableCell>
              <TableCell align="right"  style={{width:'10%',display:'none'}}>Tax</TableCell>
              <TableCell align="right"  style={{width:'10%',display:'none'}}>Payment Total</TableCell>
              <TableCell align="right"  style={{width:'10%'}}>Actions</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
              {
                payCart.map((acc,sl)=>(<>
                   <TableRow key={sl} style={{background: sl == pay_ind_id ? '#dedede':'',  transition: '1s'}}>
                  <TableCell align="left">{sl+parseFloat(1)}</TableCell>
                  <TableCell align="left">{acc.to_acc_name}</TableCell>
                  <TableCell align="left">{acc.from_acc_name}</TableCell>
                  {/* <TableCell align="left">{acc.voucher_no}</TableCell> */}
                  <TableCell align="right">{format(parseFloat(acc.pay_amount).toFixed(2))}</TableCell>
                  <TableCell align="right" style={{display:''}}>{format(parseFloat(acc.discount_amount).toFixed(2))}</TableCell>
                  <TableCell align="right" style={{display:'none'}}>{format(parseFloat(acc.tax_amount).toFixed(2))}</TableCell>
                  <TableCell align="right" style={{display:'none'}}>{format(parseFloat(acc.pay_total).toFixed(2))}</TableCell>
                  <TableCell align="right">
                  <EditIcon style={{cursor:'pointer',color:'#2e482e',marginLeft:'2px'}} onClick={()=>{editJournalTran(acc,sl)}}></ EditIcon>
                  <span style={{width:'2px'}}> </span>
                  <RemoveCircleIcon style={{cursor:'pointer',color:'#582727',marginLeft:'2px'}} onClick={()=>{removeJournalTran(sl)}}></ RemoveCircleIcon>
                  </TableCell>
              
                  </TableRow>
                </>))
              }

              {
                payCart.length > 0 ? (<>
                <TableRow style={{background: 'rgb(249 243 243)',transition: '1s'}}>
                    <TableCell align="right" colSpan={3} style={{fontWeight:'bold'}}>Total  : </TableCell>
                    <TableCell align="right" style={{fontWeight:'bold'}}> {format(parseFloat(_.sumBy(payCart,(tran)=>parseFloat(tran.pay_amount))).toFixed(2))}</TableCell>
                    <TableCell align="right" style={{fontWeight:'bold',display:''}}> {format(parseFloat(_.sumBy(payCart,(tran)=>parseFloat(tran.discount_amount))).toFixed(2))}</TableCell>
                    <TableCell align="right" style={{fontWeight:'bold',display:'none'}}> {format(parseFloat(_.sumBy(payCart,(tran)=>parseFloat(tran.tax_amount))).toFixed(2))}</TableCell>
                    <TableCell align="right" style={{fontWeight:'bold',display:'none'}}> {format(parseFloat(_.sumBy(payCart,(tran)=>parseFloat(tran.pay_total))).toFixed(2))}</TableCell>
                    <TableCell align="right">----------</TableCell>
                 </TableRow>
                </>):''
                 
              }
             
         


     
        </TableBody>
      </Table>
    </TableContainer>


    <TableContainer  style={{float:'right'}}>
      <Table className={classes.table} size="small" aria-label="a dense table">
     
          <TableRow > 
             <TableCell style={{width: '325px'}}><TextareaAutosize 
              ref={narration_ref}
              
               onChange={(e)=>{narration_set(e.target.value)}} value={narration}  name="narration" style={{float:'left',marginTop:'20px',width: '325px'}} aria-label="Narration..." rowsMin={3} placeholder="Narration..." />
             </TableCell>

             <TableCell colSpan={2}></TableCell>

           
          
          </TableRow>
        
      </Table>
    </TableContainer>


        </Grid>



        </Grid>

      </Grid>


  
         <Grid item xs={12}>
  <Button style={{marginTop: '25px'}}
        variant="contained"
        color="primary"
        size="small"
        className={classes.button}
        style={{color: 'white',
          backgroundColor: '#115f5f'}}
        disabled={loadingSave}
        startIcon={<SaveIcon />}
        onClick={saveFormAction}
      >
        Save
      </Button>
  </Grid>         


     </Paper>

     {
      loadingList==true?(<b>Loading...</b>):(
        <Paper className={classes.paper} style={{marginTop:'20px'}}>
        <MUIDataTable
      title={"Creditor/ Supplier Payment Entry List"}
      data={payments}
      columns={columns}
      options={options}
      adjustForCheckbox={false} 
      displaySelectAll={false}
      />
      </Paper>
      )
      
     }

     
    </div>
        
    );
}


 const options = {
   filterType: 'checkbox',
 };
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },

root: {},
'& .MuiTextField-root': {
  margin: theme.spacing(1),
  width: '25ch',
 },
  paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
  },
  pageEntryLabel:{
      color: '#7754cc',
      margin: '0px',
      padding: '0px',
      marginTop: '-11px',
      textAlign: 'left',
      marginLeft: '0px',
      marginBottom: '15px'
  },
  fullWidth:{
      width:'100%'
  },
  option: {
      fontSize: 15,
      '& > span': {
        marginRight: 10,
        fontSize: 18,
      },
    },
    plusLinkDiv:{
        position:'relative'  
  },
  plusLink:{
    margin: 0,
    padding: 0,
    marginTop: '-21px',
    fontSize: '29px',
    height: '21px',
    textAlign: 'right',
    position: 'absolute',
    right: 0,
    color: '#3e8d54'
  }
}));

const mapStateToPops = (state)=>{
      return {
        currentRoute:state.currentRouteReducer,
        authInfo:state.authInfoReducer
      }
}
export default connect(mapStateToPops,{currentRouteSet})(GroupManage);